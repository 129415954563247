<template>
  <div v-loading="loading" class="missed-tasks">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="header"
    >
      <h1 class="subtitle">
        Missed Tasks
      </h1>
      <h4 class="last-updated">
        Last Updated: {{ lastUpdated }}
      </h4>
    </el-row>
    <el-row
      type="flex"
      justify="space-between"
      class="controls"
    >
      <el-row>
        <el-select
          :value="companyFilter"
          size="small"
          multiple
          filterable
          clearable
          placeholder="Filter by companies"
          @change="handleFilterChange($event, 'company')"
        >
          <el-option
            v-for="company in companyFilterOptions"
            :key="company.id"
            :label="company.human_name"
            :value="company.id"
          />
        </el-select>
        <el-select
          :value="posterFilter"
          size="small"
          multiple
          filterable
          clearable
          placeholder="Filter by posters"
          @change="handleFilterChange($event, 'poster')"
        >
          <el-option
            v-for="poster in posterFilterOptions"
            :key="poster.id"
            :label="poster.name"
            :value="poster.id"
          />
        </el-select>
      </el-row>
      <el-button size="small" @click="fetchData">
        Refresh Page
      </el-button>
    </el-row>
    <el-row>
      <missed-tasks-table :properties="properties" />
    </el-row>
  </div>
</template>

<script>
import { format } from 'date-fns'

import MissedTasksTable from './_components/MissedTasksTable'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'MissedTasks',
  components: {
    'missed-tasks-table': MissedTasksTable
  },
  data () {
    return {
      loading: false,
      lastUpdated: '',
      properties: [],
      companyFilter: [],
      companyFilterOptions: [],
      posterFilter: [],
      posterFilterOptions: []
    }
  },
  created () {
    this.fetchFilterOptions()
    // restore filters from route query
    const query = this.$route.query
    if (query.company) {
      this.companyFilter = Array.isArray(query.company)
        ? query.company.map(str => parseInt(str)) : [parseInt(query.company)]
    }
    if (query.poster_id) {
      this.posterFilter = Array.isArray(query.poster_id)
        ? query.poster_id.map(str => parseInt(str)) : [parseInt(query.poster_id)]
    }
    const newQuery = JSON.parse(JSON.stringify(query))
    this.$router.replace(
      {
        path: this.$route.path,
        query: newQuery
      }
    ).catch(err => err)
    this.fetchData()
  },
  methods: {
    /**
     * Fetch missed full service tasks
     */
    async fetchData () {
      try {
        this.loading = true
        const params = new URLSearchParams()
        for (const company of this.companyFilter) {
          params.append('company', company)
        }
        for (const poster of this.posterFilter) {
          params.append('poster_id', poster)
        }
        this.properties = await CraigslistAPI.missed_tasks.list(params)
        this.lastUpdated = format(new Date(), 'h:mm:ss a')
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Handler for when company filter select has been changed.
     *
     * @param {Array} newSelected - array of selected company ids
     */
    handleFilterChange (newSelected, filterType) {
      if (filterType === 'company') {
        this.companyFilter = newSelected
      } else if (filterType === 'poster') {
        this.posterFilter = newSelected
      }

      // persist filters via route query
      const query = {}
      if (this.companyFilter.length) {
        query.company = this.companyFilter
      }
      if (this.posterFilter.length) {
        query.poster_id = this.posterFilter
      }
      this.$router.replace(
        {
          path: this.$route.path,
          query: query
        }
      ).catch(err => err)
    },
    /**
     * Fetches the queue without any filters
     * in order to populate the company filters with CaaS companies.
     */
    async fetchFilterOptions () {
      try {
        const queue = await CraigslistAPI.missed_tasks.list()
        const lookup = {}
        this.companyFilterOptions = queue.reduce((total, current) => {
          if (!lookup[current.company.id]) {
            lookup[current.company.id] = true
            total.push({ id: current.company.id, human_name: current.company.human_name })
          }
          return total
        }, []).sort((a, b) => a.human_name.localeCompare(b.human_name))

        const posterLookup = {}
        this.posterFilterOptions = queue.reduce((total, current) => {
          if (current.assigned_to && !posterLookup[current.assigned_to.id]) {
            posterLookup[current.assigned_to.id] = true
            total.push({ id: current.assigned_to.id, name: current.assigned_to.name })
          }
          return total
        }, []).sort((a, b) => a.name.localeCompare(b.name))
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.missed-tasks {
  .header {
    margin: 0.5em 0;
    .subtitle {
      margin: 0;
    }
    .last-updated {
      margin: 0;
    }
  }
  .controls {
    margin: 0.5em 0;
    .el-select {
      margin-right: 1em;
    }
  }
}
</style>
