<template>
  <data-table
    :data="properties"
    class="missed-tasks-table"
    @row-click="handleRowClick"
  >
    <el-table-column
      label="Property Name"
      prop="property.name"
      sortable
    >
      <template slot-scope="scope">
        {{ scope.row.property.name }}
        <el-tag
          v-if="scope.row.property.premium"
          size="mini"
        >
          Premium
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      label="Company Name"
      prop="company.human_name"
      sortable
    />
    <el-table-column
      label="Service Status"
      prop="property.service_status"
      sortable
    >
      <template slot-scope="scope">
        <el-tag
          size="medium"
          :type="getStatusTagType(scope.row.property.service_status)"
          disable-transitions
        >
          {{ scope.row.property.service_status }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      label="Timezone"
      prop="timezone"
      sortable
    />
    <el-table-column
      label="Poster"
      prop="assigned_to.name"
      sortable
    />
    <el-table-column
      label="Last Posting Completed"
      prop="posting_completed"
      sortable
    >
      <template slot-scope="scope">
        {{ formatDate(scope.row.last_posting_completed) }}
      </template>
    </el-table-column>
    <el-table-column
      label="Reason"
      prop="reasons"
      min-width="150"
    >
      <template slot-scope="scope">
        <div
          v-for="(value, key) in scope.row.reasons"
          :key="key"
        >
          <el-tag
            v-if="value"
            type="info"
            size="medium"
          >
            {{ formatReason(key) }}
          </el-tag>
        </div>
      </template>
    </el-table-column>
  </data-table>
</template>

<script>
import { formatDistanceToNow, parseISO } from 'date-fns'
import DataTable from '@/components/tables/DataTable'

import { getStatusTagType } from '@/utils/rooof'

export default {
  name: 'MissedTasksTable',
  components: {
    'data-table': DataTable
  },
  props: {
    properties: {
      type: Array,
      required: true
    }
  },
  created () {
    this.parseISO = parseISO
    this.getStatusTagType = getStatusTagType
  },
  methods: {
    /**
     * Handles when a row in the data table is clicked.
     * Navigates to the property's full service posts page.
     *
     * @param {Object} row
     */
    handleRowClick (row) {
      this.$router.push({ name: 'FullServicePosts', params: { id: row.property.id } })
    },
    /**
     * Format last completed posting date
     *
     * @param {Array} dates
     * @returns {String} - x days/hours ago
     */
    formatDate (date) {
      if (!date) {
        return ''
      }
      var lastCompletedDate = new Date(parseISO(date))
      var diffDays = formatDistanceToNow(lastCompletedDate, { addSuffix: true })
      return diffDays
    },
    /**
     * Format reason for missed task
     *
     * @param {Array} reason
     * @returns {String} - formatted reason
     */
    formatReason (reason) {
      if (!reason) {
        return ''
      }
      switch (reason) {
        case 'gte_week_since_last_completion':
          return 'More than 7 days since last completion'
        case 'no_posts_scheduled_today':
          return 'No posts scheduled for today'
        case 'not_enough_posts_today':
          return 'Not enough posts scheduled for today'
        case 'unable_to_post_yesterday':
          return 'Marked as unable to post'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.missed-tasks-table {
  ::v-deep .el-table__row:hover {
    cursor: pointer;
  }
}
</style>
